import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"

export default function Intent({ children, to }) {
  return (
    <Link
      hex="#F13945"
      className="pb-2"
      paintDrip
      to={to}
      activeStyle={{
        borderBottom: "2px solid #0066CC",
      }}
    >
      {children}
    </Link>
  )
}
