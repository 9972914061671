import React from "react"

export default function Footer() {
  return (
    <footer className="px-2 py-4 lg:py-16 bg-tertiary text-center">
      <p className="text-sm text-gray-700">
        Copyright &copy; {new Date().getFullYear()} Zey Ventures. All Rights
        Reserved.
      </p>
    </footer>
  )
}
